



































import { defineComponent, ref, onUnmounted } from '@vue/composition-api'
import { FlexiblePicture } from '@/inc/types'
import { defaultSets } from '@/inc/directives/src'

import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'FlexiblePicture',
  components: { UiPicture },
  props: {
    content: {
      type: Object as () => FlexiblePicture,
      required: true,
    },
  },
  setup(props) {
    const root = ref<HTMLElement | null>(null)
    const id = props.content.picture.rawUrl

    const onAppear = (el: Element) => {
      // Show image
      gsap.fromTo(
        el,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.8,
          onComplete: () => gsap.set(el, { clearProps: 'opacity' }),
        }
      )

      // Add parallax on image
      /*
      if (!props.content.fullWidth || !root.value) {
        return
      }

      const picture = root.value.querySelector('.picture__inner')

      gsap.fromTo(
        picture,
        {
          yPercent: -40,
        },
        {
          scrollTrigger: {
            trigger: root.value as HTMLElement,
            scrub: 0.1,
            start: '',
            id,
            markers: true,
          },
          yPercent: -60,
        }
      )
      */
    }

    onUnmounted(() => {
      ScrollTrigger.getById(id)?.kill()
    })

    return {
      root,
      onAppear,
      defaultSets,
    }
  },
})
